import { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import MyContext from "../context/MyContext";

const ModalWa = (props) =>{

	const [formInput, setFormInput] = useState({
		name: '',
        organization: '',
	}); 
	const [messageSelect, setMessageSelect] = useState(null);

	const state = useContext(MyContext);
	
	function handleChange(event){
		var newValue = event.target.value.replace(/^\s/g,'');
        setFormInput({
            ...formInput,
            [event.target.name]: newValue
        })
    }

	function changeSelectedOption(e){
		setMessageSelect(e.target.value)
	}

	function openChatWa(e){
		e.preventDefault();
		props.hide();
		window.open(`https://api.whatsapp.com/send?phone=628114387486&text=Saya%20${formInput.name}%20dari%20${formInput.organization}%20,%20ingin%20menanyakan%20tentang%20${messageSelect}%20pada%20aplikasi%20Idtrium.`
		, '_blank');
	}

	return(
		<Modal show={props.show} onHide={props.hide} className="modal-wrapper">
			<Modal.Body>
				<form id="modalWa" className="modal-form-wrapper" onSubmit={openChatWa}>
					<div className="modal-input-wrapper">
						<div className="modal-input-group">
							<label>{state.language==='Eng' ? 'Name': 'Nama'}</label>
							<input className="modal-form-input" 
								name="name" 
								type="text"
								value={formInput.name}
								required
								onChange={handleChange}
							/>
						</div>
						<div className="modal-input-group">
							<label>{state.language==='Eng' ? 'Organization': 'Organisasi'}</label>
							<input className="modal-form-input" 
								name="organization" 
								type="text" 
								value={formInput.organization}
								required
								onChange={handleChange}
							/>
						</div>
						<div className="modal-input-group">
							<label>{state.language==='Eng' ? 'Message Option': 'Opsi Pesan'}</label>
							<div className="modal-input-select">
								<select name="messageOption" defaultValue="" onChange={changeSelectedOption} required>
									<option value="" defaultValue disabled>{state.language==='Eng' ? '-- Choose message option --': 'Pilih Pesan'}</option>
									<option value="Customer Services">Customer Services</option>
									<option value="Sales">Sales</option>
								</select>
							</div>
						</div>
					</div>
				</form>
			</Modal.Body>
			<Modal.Footer>
				<button className="modal-footer-button cancel" onClick={props.hide}>
					{state.language==='Eng' ? 'Close': 'Tutup'}
				</button>
				<button className="modal-footer-button submit" type="submit" form="modalWa">
					{state.language==='Eng' ? 'Start Chat': 'Mulai Chat'}
				</button>
			</Modal.Footer>
		</Modal>
	)
}

export default ModalWa;