import HomeBanner from "../component/HomeBanner"
import HomeFeature from "../component/HomeFeature";
import HomeHandle from "../component/HomeHandle";
import HomeReal from "../component/HomeReal";
import HomeSeparator from "../component/HomeSeparator";

const Home = () =>{
	return(
		<div>
			<HomeBanner/>
			<HomeSeparator/>
			<HomeHandle/>
			<HomeFeature/>
			<HomeReal/>
		</div>
	)
}

export default Home;