import React, { useContext, useState } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Logo from '../assets/img/Logo.png';
import IconWA from '../assets/img/Icon_WA.png';
import IconIndo from '../assets/img/indonesian.png';
import IconEn from '../assets/img/english.png';
import ModalWa from './ModalWa';
import MyContext from '../context/MyContext';

const NavbarIdTrium = () =>{

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const state = useContext(MyContext);

	const changeLang = (lang) =>{
		state.setLanguage(lang)
	}
    return(
		<>
			<ModalWa show={show} hide={handleClose}/>
			<Navbar className="navbar-wrapper" expand="lg" >
				<Container>
					<Navbar.Brand as={NavLink} to="/" className="logo-wrapper">
						<img src={Logo} alt="Logo IdTrium"/>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="mr-auto"
							activeKey="/"
						>
							<NavDropdown title={state.language === 'Eng' ? 'Product' : 'Produk'} id="basic-nav-dropdown">
								<NavDropdown.Item as={NavLink} to="/product">Passive Vaccine Storage Device</NavDropdown.Item>
								<NavDropdown.Item as={NavLink} to="/telehealth">Telehealth Diagnostic Solution</NavDropdown.Item>
								<NavDropdown.Item as={NavLink} to="/remote-patient">Remote Patient Monitoring</NavDropdown.Item>
							</NavDropdown>
							<Nav.Item>
								<Nav.Link as={NavLink} to="/about">
									{state.language==='Eng' ? 'About': 'Tentang Kami'}
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link as={NavLink} to="/contact">
									{state.language==='Eng' ? 'Contact Us': 'Hubungi Kami'}
								</Nav.Link>
							</Nav.Item>
						</Nav>
						<Nav className="align-items-center">
							<div className="nav-lang">
								<button onClick={()=>changeLang('In')} className={state.language==='Eng' ? 'disabled': 'enabled'}>
									<img src={IconIndo} alt="Indonesia"/>
								</button>
								<button onClick={()=>changeLang('Eng')} className={state.language==='Eng' ? 'enabled': 'disabled'}>
									<img src={IconEn} alt="English"/>
								</button>
							</div>
							<Nav.Link as={NavLink} to="/contact" className="navbar-buybtn">
								{state.language==='Eng' ? 'Buy Now': 'Beli Produk'}
							</Nav.Link>
							<button className="navbar-wabtn" onClick={handleShow}>
								<img src={IconWA} alt="Whatsapp"/>
							</button>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</>
    )
}

export default NavbarIdTrium;
