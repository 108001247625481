import React, { useEffect } from 'react';
import MyProvider from './context/MyProvider';
import './App.css';
import { Route, Switch, withRouter } from 'react-router-dom';
import Home from './container/Home';
import Contact from './container/Contact';
import About from './container/About';
import TeleHealth from './container/TeleHealth';
import RemotePatient from './container/RemotePatient';
import NavbarIdTrium from './component/Navbar';
import './App.scss';
import Footer from './component/Footer';

import ReactGA from 'react-ga';
import Testing from './container/Testing';
const TRACKING_ID = "UA-128935349-3"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {

	useEffect(()=>{
		ReactGA.pageview(window.location.pathname + window.location.search);
	})

	return (
		<MyProvider>
			<NavbarIdTrium/>
			<div className="body-wrapper">
				<Switch>
					<Route path="/" exact component={Home}></Route>
					<Route path="/product" exact component={Home}></Route>
					<Route path="/telehealth" exact component={TeleHealth}></Route>
					<Route path="/remote-patient" exact component={RemotePatient}></Route>
					<Route path="/contact" component={Contact}></Route>
					<Route path="/about" exact component={About}></Route>
					<Route path="/testing" exact component={Testing}></Route>
				</Switch>
			</div>
			<Footer/>
		</MyProvider>
	);
}

export default withRouter(App);
