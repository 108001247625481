import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Image from '../assets/img/Real_01.png';
import MyContext from "../context/MyContext";

const HomeReal = () =>{

	const state = useContext(MyContext);

	return(
		<div className="home-real-wrapper">
			<Container>
				<div className="home-real-content">
					<Row>
						<Col md="6">
							<div>
								<h2 className="page-title">
									{state.language==='Eng' ? 'Use Case Overview': 'Kasus Penggunaan'}
								</h2>
								<p className="page-desc">
									{state.language==='Eng' ? 'Summary of Key Challenges and Solution ': 'Ringkasan Hambatan dan Solusi'}
								</p>
								<div className="real-content-card right">
									<h4 className="real-card-title">
										{state.language==='Eng' ? 'Overview': 'Tinjauan'}
									</h4>
									<p className="real-content-desc">
										{state.language==='Eng' ? 'The 2014 - 2015 Ebola virus disease epidemic in West Africa, researchers accelerated the development of Ebola vaccines. In Sierra Leone, the Centers for Disease Control and Prevention (CDC) sponsored a phase 2/3 trial—the Sierra Leone Trial to Introduce a Vaccine Against Ebola (STRIVE)': 'Epidemi penyakit virus Ebola 2014-2015 di Aftika Barat, para peneliti mempercepat pengembangan vaksin Ebola. Di Sierra Leone, Pusat Pengendalian dan Pencegahan Penyakit (CDC) mensponsori uji coba fase 2/3 — Uji Coba Sierra Leone untuk Memperkenalkan Vaksin Melawan Ebola (STRIVE)'}
									</p>
									<img src={Image} alt="Overview"/>
								</div>
							</div>
						</Col>
						<Col md="6">
							<div>
								<div className="real-content-card">
									<h4 className="real-card-title">
										{state.language==='Eng' ? 'Challenge': 'Hambatan'}
									</h4>
									<ul>
										<li className="real-content-desc">
											{state.language==='Eng' ? 'No appropriate housing for operating –80°C freezers under necessary ambient temperature conditions': 'Pada kondisi sekitar tidak ada tempat yang sesuai untuk mengoperasikan freezer –80 ° '}
										</li>
										<li className="real-content-desc">
											{state.language==='Eng' ? 'No reliable electricity': 'Tidak ada saluran listrik yang memadai'}
										</li>
										<li className="real-content-desc">
											{state.language==='Eng' ? 'How to transport vaccines across the country at –60°C or colder': 'Bagaimana mengangkut vaksin ke seluruh negeri pada suhu -60 ° C atau lebih dingin'}
										</li>
										<li className="real-content-desc">
											{state.language==='Eng' ? 'How to track vaccine dilution batches': 'Bagaimana melacak jumlah pengenceran vaksin'}
										</li>
									</ul>
								</div>
								<div className="real-content-card">
									<h4 className="real-card-title solution">
										{state.language==='Eng' ? 'Solution': 'Solusi'}
									</h4>
									<p className="real-content-desc">
										{state.language==='Eng' ? 'In just 3 months, STRIVE staff successfully established and maintained a cold chain for storing the candidate Ebola vaccine at a temperature of –60°C or colder for the phase 2/3 trial of vaccine in Sierra Leone and administered about 8000 doses of vaccine with {"<"}0.1% of doses rejected or damaged.': 'Hanya dalam 3 bulan, staf STRIVE berhasil membangun dan memelihara rantai dingin untuk menyimpan kandidat vaksin Ebola pada suhu -60 ° C atau lebih dingin untuk uji coba fase 2/3 vaksin di Sierra Leone dan memberikan sekitar 8000 dosis vaksin dengan <0,1% dosis ditolak atau rusak.'}
									</p>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</Container>
		</div>
	)
}

export default HomeReal;