import React, { useEffect, useState } from 'react';
import { Container } from "react-bootstrap";

const Testing = () =>{
	// const [imageSrc, setImageSrc] = useState(0);
	const [count, setCount] = useState(0);
	const [loading, setLoading] = useState(false);
	const [dataList, setDataList] = useState([]);
	// const [dataSearch, setDataSearch] = useState('');
	const [errorMsg, setErrorMsg] = useState('');

	// const onImageChange = (event) => {
	// 	if (event.target.files && event.target.files[0]) {
	// 	  setImageSrc(URL.createObjectURL(event.target.files[0]));
	// 	}
	// }

	// const onSearch = (event) =>{
	// 	console.log(event.target.value);
	// 	setDataSearch(event.target.value);
	// }

	const fetchData = () =>{
		setLoading(true)
		fetch(`https://jsonplaceholder.typicode.com/users`)
		.then((response) => response.json())
		.then((actualData) => {
			setLoading(true)
			setDataList(actualData);
			console.log(actualData)

		}).catch(error => {
			setLoading(true)
			setErrorMsg('error ni', error)
			console.error('There was an error!', error);
		});
	}

	const onDecrement = () =>{
		setCount(count-1);
	}

	const onIncrement = () =>{
		setCount(count+1);
	}

	useEffect(()=>{
		fetchData();
	}, [])
	
	return(
		<Container>
			{/* <p>
				<input type="file"  accept="image/*" name="image" id="file"  onChange={onImageChange}/>
			</p>
			<p><label htmlFor="file">Upload Image</label></p>
			<img id="target" alt="test" src={imageSrc}/> */}
			<p>
			{
				loading? errorMsg : ''
			}
			</p>
			<div>
				<button onClick={onDecrement}>-</button>
				<span> {count} </span>
				<button onClick={onIncrement}>+</button>
			</div>
			{dataList.length >0 && dataList.map((a, index)=>{
				return(
					<div key={a.name+index}>{a.name} - {a.email}</div>
				)
			})}
		</Container>
	)
}

export default Testing;