import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import IconLocation from '../assets/img/Icon_location_blue.png';
import MyContext from "../context/MyContext";
import ContactForm from "./ContactForm";

const ContactBanner = () =>{

	const state = useContext(MyContext);

	return(
		<div className="contact-banner-wrapper">
			<Container>
				<Row>
					<Col lg="6">
						<div className="contact-banner-card">
							<div className="contact-banner-address">
								<span>{state.language==='Eng' ? 'Office Address :': 'Alamat Kantor :'}</span>
								Pakuwon Tower Lt 26 Unit J
								Casablanca Raya Street No. 88
								Jakarta Selatan
							</div>
							<a rel="noreferrer" href="https://www.google.com/maps/dir//pakuwon+tower/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x2e69f3fcb6f8787f:0xca722c28315adfa4?sa=X&ved=2ahUKEwjD3ODx8dfvAhVDfX0KHfpKCjAQ9RcwInoECDcQBQ" target="_blank" className="contact-location-link">
								<span className="contact-location-icon">
									<img src={IconLocation} alt="Icon Location"/>
								</span>
								<span>{state.language==='Eng' ? 'Click for detail Location': 'Peta Lokasi '}</span>
							</a>
						</div>
					</Col>
					<Col lg="6" className="contact-banner-form">
						<ContactForm/>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default ContactBanner;