import React, { useContext } from 'react';
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import LogoWhite from '../assets/img/Logo_white.png';
import IconEmail from '../assets/img/Icon_email.png';
import IconMobile from '../assets/img/Icon_mobile.png';
import IconLocation from '../assets/img/Icon_location.png';
import MyContext from '../context/MyContext';

const Footer = () =>{

	const state = useContext(MyContext);

    return(
        <div className="footer" >
            <Container>
                <Row>
					<Col xl="9" lg="8" >
						<Navbar.Brand as={NavLink} to="/" className="footer-logo">
							<img src={LogoWhite} alt="Logo IdTrium"/>
						</Navbar.Brand>
						<Nav className="footer-nav"
								activeKey="/product"
						>
							<Nav.Item>
								<Nav.Link as={NavLink} to="/about">{state.language==='Eng' ? 'About': 'Tentang Kami'}</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link as={NavLink} to="/contact">{state.language==='Eng' ? 'Contact Us': 'Hubungi Kami'}</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link as={NavLink} to="/testing">{state.language==='Eng' ? 'Test': 'Test'}</Nav.Link>
							</Nav.Item>
						</Nav>
						<p className="footer-copyright">&copy; 2021 IDTrium Solusi Teknologi. All rights reserved</p>
					</Col>
					<Col xl="3" lg="4" className="footer-info">
						<h3>{state.language==='Eng' ? 'Reach Us': 'Kontak Kami'}</h3>
						<ul className="footer-info-list">
							<li>
								<span className="footer-info-icon">
									<img src={IconEmail} alt="Icon Email"/>
								</span>
								<span className="footer-info-text">info@idtrium.com</span>
							</li>
							<li>
								<span className="footer-info-icon">
									<img src={IconMobile} alt="Icon Mobile"/>
								</span>
								<span className="footer-info-text">+62811-4387486</span>
							</li>
							<li>
								<span className="footer-info-icon">
									<img src={IconLocation} alt="Icon Location"/>
								</span>
								<span className="footer-info-text">
								Pakuwon Tower Level 26 Unit J
								Casablanca Raya Street No. 88
								East Jakarta, Indonesia
								</span>
							</li>
						</ul>
					</Col>
				</Row>
            </Container>
        </div>
    )
}

export default Footer;
