import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageDevice00 from '../assets/img/Product/Product_00.png';
import MyContext from "../context/MyContext";

const HomeBanner = () =>{
	const state = useContext(MyContext);

	return(
		<div className="home-banner-wrapper">
			<Container>
				<Row>
					<Col lg="8" className="home-banner-content">
						<h4 className="home-banner-head">
							{state.language==='Eng' ? 'Passive Vaccine Storage Device (PVSD)': 'Perangkat Penyimpanan Vaksin Pasif'}
						</h4>
						<h1 className="home-banner-title">
							{state.language==='Eng' ? 'Flexibility & Keep stable temperature.': 'Fleksibel & Menjaga Suhu Tetap Stabil'}
						</h1>
						<h5 className="home-banner-desc">
							{state.language==='Eng' ? 'Strengthen the vaccine supply chain in underserved regions.': 'Memperkuat rantai pasokan vaksin di daerah yang kurang terlayani'}
						</h5>
						<Link to="/contact" className="home-banner-btn" >
							{state.language==='Eng' ? 'Contact For Detail': 'Informasi Lebih Lanjut'}
						</Link>
					</Col>
					<Col lg="4">
						<div className="home-banner-imgwrapper">
							<div>
								<img src={ImageDevice00} alt="Device" />
							</div>
						</div>
						
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default HomeBanner;

