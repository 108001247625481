import BannerTelehealth from '../assets/img/Telehealth-diagnostic-solution.jpg';

const Home = () =>{
	return(
		<div className="banner-info-wrapper">
			<img src={BannerTelehealth} alt="Telehealth Banner"/>
		</div>
	)
}

export default Home;