import BannerRemote from '../assets/img/Remote-patient-monitoring.jpg';

const Home = () =>{
	return(
		<div className="banner-info-wrapper">
			<img src={BannerRemote} alt="Remote Patient Banner"/>
		</div>
	)
}

export default Home;