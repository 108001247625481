import AboutDetail from "../component/AboutDetail";
import AboutInfo from "../component/AboutInfo";

const About = () =>{
	return(
		<>
			<AboutInfo/>
			<AboutDetail/>
		</>
	)
}

export default About;