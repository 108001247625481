import { Modal } from "react-bootstrap";

const ModalResponse = (props) =>{

	return(
		<Modal show={props.show} onHide={props.hide} className='modal-response-wrapper'>
			<Modal.Header className={props.success ? 'modal-header-success': 'modal-header-error'}>
				<Modal.Title className="modal-header-title">
					{props.success ? 'Success' : 'Error'}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="modal-body-content">
				{props.success ? 'Thanks for filling out our form! We will contact you very soon.' : 'An error occurred while communicating with the server.'}
			</Modal.Body>
			<Modal.Footer>
				<button className="modal-footer-button cancel" onClick={props.hide}>
					Close
				</button>
			</Modal.Footer>
		</Modal>
	)
}

export default ModalResponse;