import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ImgFeature01 from "../assets/img/Feature_01.png";
import ImgFeature02 from "../assets/img/Feature_02.png";
import ImgFeature03 from "../assets/img/Feature_03.png";
import ImgFeature04 from "../assets/img/Feature_04.png";
import MyContext from "../context/MyContext";

const HomeFeature = () =>{
	
	const state = useContext(MyContext);

	return(
		<div className="home-feature-wrapper">
			<Container>
				<h2 className="page-title">
					{state.language==='Eng' ? 'Discover the key features': 'Fitur Utama'}
				</h2>
				<div className="home-feature-list">
					<Row>
						<Col sm="6" lg="3">
							<div className="home-feature-card">
								<span className="home-feature-text">
									{state.language==='Eng' ? 'Storage Temperature -60 ℃ down to -80 ℃': 'Suhu penyimpanan -60 ℃ hingga -80 ℃'}
								</span>
								<img src={ImgFeature01} alt="Feature One" className="home-feature-img"/>
							</div>
						</Col>
						<Col sm="6" lg="3">
							<div className="home-feature-card">
								<span className="home-feature-text">
									{state.language==='Eng' ? '7 days use without battery / electricity': 'Penggunaan 7 hari tanpa baterai / listrik'}
								</span>
								<img src={ImgFeature02} alt="Feature Two" className="home-feature-img"/>
							</div>
						</Col>
						<Col sm="6" lg="3">
							<div className="home-feature-card">
								<span className="home-feature-text">
									{state.language==='Eng' ? 'Capacity 5.4L (approx. 300 vaccine vials)': 'Kapasitas 5.4L (sekitar 300 botol vaksin)'}
								</span>
								<img src={ImgFeature03} alt="Feature Three" className="home-feature-img"/>
							</div>
						</Col>
						<Col sm="6" lg="3">
							<div className="home-feature-card">
								<span className="home-feature-text">
									{state.language==='Eng' ? 'Real-time Monitoring System ': 'Sistem pemantau waktu nyata '}
									<span>
										{state.language==='Eng' ? '(GPS Location , Temperature Monitoring, User Log / Equipment Log)': '(Lokasi GPS , Pemantau Suhu, Log Pengguna / Log Peralatan)'}
									</span>
								</span>
								<img src={ImgFeature04} alt="Feature Four" className="home-feature-img"/>
							</div>
						</Col>
					</Row>
				</div>
			</Container>
		</div>
	)
}

export default HomeFeature;