import { useContext, useState } from "react";
import emailjs from 'emailjs-com';
import ModalResponse from "./ModalResponse";
import MyContext from "../context/MyContext";

const ContactForm = () =>{

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showModalSubmit, setShowModalSubmit] = useState(false);
	const [formSubmitSuccess, setFormSubmitSucess] = useState(false);
	const [formInput, setFormInput] = useState({
		fullName: '',
        companyName: '',
        phoneNumber: '',
        email: '',
        message: '', 
	}); 
	const [subjectSelect, setSubjectSelect] = useState();

	const state = useContext(MyContext);

	const hideModalSubmit = () => {
		setShowModalSubmit(false);
		setIsSubmitting(false);
		setFormInput({
			fullName: '',
			companyName: '',
			phoneNumber: '',
			email: '',
			message: '', 
		});
		setSubjectSelect('');
	}
	function handleChange(event){
		var newValue = event.target.value.replace(/^\s/g,'');
        setFormInput({
            ...formInput,
            [event.target.name]: newValue
        })
    }
	
	function changeSelectedOption(e){
		setSubjectSelect(e.target.value)
	}

	function handleSubmit(e){
		e.preventDefault();
		setIsSubmitting(true);

		emailjs.send('idtrium_server', 'idtrium_template', {
		    subject_email: subjectSelect, 
		    full_name: formInput.fullName, 
		    company_name: formInput.companyName, 
		    email_address: formInput.email, 
		    phone_number: formInput.phoneNumber,
		    message_email: formInput.message,
		}, 'user_gJL945AlE48j6XMExbe1M')
		.then((result) => {
			console.log('SUCCESS!', result.status, result.text);
			setShowModalSubmit(true)
			setFormSubmitSucess(true)

		}, (error) => {
			console.log('FAILED...', error);
			setShowModalSubmit(true)
		})
	}

	return(
		<div>
			<ModalResponse show={showModalSubmit} hide={hideModalSubmit} success={formSubmitSuccess} />
			<form className="contact-form-wrapper" onSubmit={handleSubmit}>
				<h4 className="contact-form-title">{state.language==='Eng' ? 'Contact Us': 'Hubungi Kami'}</h4>
                <div className="contact-input-wrapper">
					<div className="contact-input-group">
						<label>{state.language==='Eng' ? 'Full Name': 'Nama Lengkap'}</label>
						<input className="contact-form-input" value={formInput.fullName}
							name="fullName" 
							type="text" 
							required
							onChange={handleChange}
						/>
					</div>
					<div className="contact-input-group">
						<label>{state.language==='Eng' ? 'Company Name': 'Nama Perusahaan'}</label>
						<input className="contact-form-input" value={formInput.companyName}
							name="companyName" 
							type="text" 
							required
							onChange={handleChange}
						/>
					</div>
					<div className="contact-input-group">
						<label>{state.language==='Eng' ? 'Mobile Phone No.': 'Nomor Telp / HP'}</label>
						<input className="contact-form-input" value={formInput.phoneNumber}
							name="phoneNumber" 
							pattern="^[0-9]*$"
							type="text" 
							required
							onChange={handleChange}
						/>
					</div>
					<div className="contact-input-group">
						<label>{state.language==='Eng' ? 'Email Address': 'Alamat Email'}</label>
						<input className="contact-form-input" value={formInput.email}
							name="email" 
							pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
							type="email" 
							required
							onChange={handleChange}
						/>
					</div>
					<div className="contact-input-group">
						<label>{state.language==='Eng' ? 'Email Subject': 'Subjek Email'}</label>
						<div className="contact-input-select">
							<select name="messageOption" className="contact-form-input" defaultValue="" value={subjectSelect} onChange={changeSelectedOption} required>
								<option value="" disabled>{state.language==='Eng' ? '-- Choose subject option --': 'Pilih Subjek Email'}</option>
								<option value="Customer Services">Customer Services</option>
								<option value="Sales">Sales</option>
							</select>
						</div>
					</div>
					<div className="contact-input-group">
						<label>{state.language==='Eng' ? 'Message': 'Pesan'}</label>
						<textarea
							name="message" value={formInput.message}
							className="form-contact-textarea"
							onChange={handleChange}
							required
							rows="4"
						/>
					</div>
                </div>
                <div className="contact-form-btn">
                    <button 
                        type="submit"
                        disabled={isSubmitting}
                    >
						{isSubmitting ? (state.language==='Eng'? 'Sending...' : 'Mengirim...') : (state.language==='Eng'? 'Send Message' : 'Kirim')}
                    </button>
                </div>
            </form>
		</div>
	)
}

export default ContactForm;