import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ImageHandleOne from "../assets/img/Handle_01.png";
import ImageHandleTwo from "../assets/img/Handle_02.png";
import ImageHandleThree from "../assets/img/Handle_03.png";
import MyContext from "../context/MyContext";

const HomeHandle = () =>{
	
	const state = useContext(MyContext);

	return(
		<div className="home-handle-wrapper">
			<Container>
				<h2 className="page-title">
					{state.language==='Eng' ? 'How to handle': 'Cara Perawatan'}
				</h2>
				<Row className="home-handle-list"> 
					<Col md="4">
						<img src={ImageHandleOne}  alt="Step One" className="home-handle-img"/>
						<span className="home-handle-text">
							{state.language==='Eng' ? 'Wipe with a Soft Towel': 'Bersihkan dengan handuk lembut'}
						</span>
					</Col>
					<Col md="4">
						<img src={ImageHandleTwo}  alt="Step Two" className="home-handle-img"/>
						<span className="home-handle-text">
							{state.language==='Eng' ? 'Lifting the Device': 'Posisi Mengangkat perangkat'}
						</span>
					</Col>
					<Col md="4">
						<img src={ImageHandleThree}  alt="Step Three" className="home-handle-img"/>
						<span className="home-handle-text">
							<span>
								{state.language==='Eng' ? 'Securing the Device for Transport': 'Mengikat perangkat untuk diangkut'}
							</span>
							<span>
								{state.language==='Eng' ? '* For device tranport only': '*Hanya untuk transportasi perangkat*'}
							</span>
						</span>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default HomeHandle;