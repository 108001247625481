import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import IconAboutOne from '../assets/img/Feature_01.png';
import IconAboutTwo from '../assets/img/Feature_04.png';
import IconAboutThree from '../assets/img/Feature_05.png';
import MyContext from "../context/MyContext";

const AboutInfo = () =>{

	const state = useContext(MyContext);

	return(
		<div className="about-info-wrapper">
			<Container>
				<div className="about-info-head">
					<span>{state.language==='Eng' ? 'IDTrium established in 2020': 'IDTrium berdiri sejak 2020'}</span>
					{state.language==='Eng' ? 'As part of Indocyber Global Teknologi focusing on IOT Solution dedicated to deliver highly effective solutions in the field of IOT Business and Technology.': 'Sebagai bagian dari Indocyber Global Teknologi yang berfokus pada Solusi IOT yang didedikasikan untuk memberikan solusi yang sangat efektif di bidang Bisnis dan Teknologi IOT.'}
				</div>
				<Row>
					<Col lg="4" className="about-info-list">
						<img src={IconAboutOne} alt="Icon Info" className="about-info-icon"/>
						<span className="about-info-title">
							{state.language==='Eng' ? 'Future Expanding': 'Memperluas Masa Depan'}
						</span>
						<span className="about-info-desc">
							{state.language==='Eng' ? 'To become a One Stop Shop for an end to end for IOT Solution and Services.': 'Untuk menjadi One Stop Shop dengan Solusi end-to- end dan Layanan IOT.'}
						</span>
					</Col>
					<Col lg="4" className="about-info-list">
						<img src={IconAboutTwo} alt="Icon Info" className="about-info-icon"/>
						<span className="about-info-title">
							{state.language==='Eng' ? 'Partnering with Xingtera': 'Bermitra dengan Xingtera'}
						</span>
						<span className="about-info-desc">
							{state.language==='Eng' ? 'As a Strategic Partner and Qualcomm as Technology Driven Company.': 'Sebagai Mitra Strategis dan Qualcomm sebagai Perusahaan Berbasis Teknologi.'}
						</span>
					</Col>
					<Col lg="4" className="about-info-list">
						<img src={IconAboutThree} alt="Icon Info" className="about-info-icon"/>
						<span className="about-info-title">
							{state.language==='Eng' ? 'Open For Partnering': 'Terbuka untuk bermitra'}
						</span>
						<span className="about-info-desc">
							{state.language==='Eng' ? 'With any local IOT related company in Indonesia': 'Dengan perusahaan IOT lokal di Indonesia'}
						</span>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default AboutInfo;