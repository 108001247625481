import { useReducer } from 'react';
import MyContext from './MyContext';

const MyProvider = (props) =>{

	const initialState = {
		language : 'Eng',
	};

	const actions = {
		SET_LANGUAGE: 'SET_LANGUAGE',
	}

	function reducer(state, action) {
		switch (action.type) {
		case actions.SET_LANGUAGE:
			console.log(action.value)
			return {
				language: action.value
			}
		default:
			throw new Error();
		}
	}

	const [state, dispatch] = useReducer(reducer, initialState);

	const value = {
		language: state.language,
		setLanguage: (value) => { dispatch ({type: actions.SET_LANGUAGE, value: value})},
	}

	return (
		<MyContext.Provider
			value={value}
		>
			{props.children}
		</MyContext.Provider>
	);
	
}
export default MyProvider;

