import { Container } from "react-bootstrap";
import LogoCompanyOne from "../assets/img/Logo_qualcomm.png";
import LogoCompanyTwo from "../assets/img/Logo_aucma.png";

const HomeSeparator = () =>{
	return(
		<div className="home-separator-wrapper">
			<Container>
				<div className="home-separator-line"></div>
				<div className="home-separator-company">
					<img src={LogoCompanyOne} alt="Logo QualComm"/>
					<img src={LogoCompanyTwo} alt="Logo Aucma"/>
				</div>
				<div className="home-separator-line"></div>
			</Container>
		</div>
	)
}

export default HomeSeparator;