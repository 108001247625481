import { useContext } from 'react';
import { Container } from 'react-bootstrap';
import Logo from '../assets/img/Logo_big.png';
import MyContext from '../context/MyContext';

const AboutDetail = () =>{
	
	const state = useContext(MyContext);

	return(
		<div className="about-detail-wrapper">
			<Container>
				<ul className="about-detail-list">
					<li>
						<ul className="about-detail-listing">
							<li>
								<div className="about-detail-card">
									{state.language==='Eng' ? 'Vaccine Storage Solutions': 'Solusi Penyimpanan Vaksin'}
								</div>
							</li>
							<li>
								<div className="about-detail-card">
									{state.language==='Eng' ? 'Smart Metering': 'Pengukuran Cerdas'}
								</div>
							</li>
							<li>
								<div className="about-detail-card">
									{state.language==='Eng' ? 'Smart Manufacturing': 'Manufaktur Cerdas'}
								</div>
							</li>
							<li>
								<div className="about-detail-card">
									{state.language==='Eng' ? 'Smart Poultry': 'Peternakan Cerdas'}
								</div>
							</li>
						</ul>
					</li>
					<li className="about-detail-logo">
						<img src={Logo} alt="Logo IdTrium"/>
						<span></span>
					</li>
					<li>
						<ul className="about-detail-listing bottom">
							<li>
								<div className="about-detail-card">
									{state.language==='Eng' ? 'Smart Farming': 'Perkebunan Cerdas'}
								</div>
							</li>
							<li>
								<div className="about-detail-card">
									{state.language==='Eng' ? 'Smart Healthcare': 'Perawatan Kesehatan Cerdas'}
								</div>
							</li>
							<li>
								<div className="about-detail-card">
									{state.language==='Eng' ? 'Smart Agriculture': 'Pertanian Cerdas'}
								</div>
							</li>
							<li>
								<div className="about-detail-card">
									{state.language==='Eng' ? 'Smart Cities': 'Kota Cerdas'}
								</div>
							</li>
						</ul>
					</li>
				</ul>
			</Container>
		</div>
	)
}

export default AboutDetail;